import react from 'react';
import HeadingInfoDetail from './HeadingInfoDetail';

export default function ParkingInfoBox({ apartment }) {


    return (


        (apartment.parking === null) ? null : (
            <div className="infobox">
                <h3> Parking Info </h3>
                <div className="containerGridLeft">
                    <HeadingInfoDetail heading="Parking Type" detail={apartment.parking.parkingType === null ? "N/A" : apartment.parking.parkingType} />
                    <HeadingInfoDetail heading="Parking Number" detail={apartment.parking.parkingNumber === null ? "N/A" : apartment.parking.parkingNumber} />
                    <HeadingInfoDetail heading="Parking Price" detail={apartment.parking.parkingPrice === null ? "N/A" : (apartment.parking.parkingPrice === 0 ? "N/A" : "$" + apartment.parking.parkingPrice)} />
                </div>
            </div>
        )

    )
}