import React, { useState } from 'react';
import './ImageSlider.css'; // Create and import your CSS for styling

const ImageSlider = ({ photos }) => {
    const [current, setCurrent] = useState(0);

    const allPhotos = photos.length > 0 ? photos : ["https://realty20.com/wp-content/uploads/2023/12/Photos-Coming-Soon.png"];

    const nextSlide = () => {
        setCurrent(current === allPhotos.length - 1 ? 0 : current + 1);
    };

    const prevSlide = () => {
        setCurrent(current === 0 ? allPhotos.length - 1 : current - 1);
    };


    return (
        <div className="slider">
            {allPhotos.map((photo, index) => (
                <div className={index === current ? 'slide active' : 'slide'} key={index}>
                    {index === current && (
                        <img src={photo} alt='apartment' className="image" />
                    )}
                </div>
            ))}
            <button className='left-arrow' onClick={prevSlide}>&lt;</button>
            <button className='right-arrow' onClick={nextSlide}>&gt;</button>
        </div>
    );
};

export default ImageSlider;
