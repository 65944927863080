import React from "react";
import "./HeadingInfoDetail.css"

export default function HeadingInfoDetail({ heading, detail }) {

    return (
        <div className="flexVertical">
            <h5> {heading} </h5>
            <p> {detail} </p>
        </div>
    )
}