import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import ImageSlider from '../components/ImageSlider'; // Adjust the path as needed
import BuildingInfoBox from './listingcomponents/BuildingInfoBox';
import ApartmentInfoBox from './listingcomponents/ApartmentInfoBox';
import DescriptionBox from './listingcomponents/DescriptionBox';
import ParkingInfoBox from './listingcomponents/ParkingInfoBox';
import SingleApartmentMap from './listingcomponents/SingleApartmentMap';
import ClipLoader from "react-spinners/ClipLoader";

import "./ListingPage.css";
import { API_BASE_URL, ERROR_MESSAGE_LISTING } from '../constants';


export default function ListingPage() {

    const location = useLocation();

    const { id } = useParams();
    const [apartment, setApartment] = useState(location.state?.apartment);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (!apartment) {
            setLoading(true);
            fetch(API_BASE_URL + "/apartment/" + id)
                .then(response => response.json())
                .then(data => {
                    setApartment(data["listings"][0]);
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Error:', error);

                    setLoading(false);
                });
        }
    }, [id, apartment]);

    if (isLoading) return <div className="main-body-container"> <div class="text-center"> <ClipLoader size={45} color={"#202a54"} loading={isLoading} /> </div> </div>;
    if (!apartment) return <div className="main-body-container"> <p className="text-center"> {ERROR_MESSAGE_LISTING} </p> </div>;

    return (

        <div className="main-body-container">

            <div style={{ width: "100%" }}>
                <a style={{ fontFamily: "var(--font-1)" }} href="javascript:history.back()">Back to Results</a>
            </div>
            <div className="containerFlex">
                <div className="leftimgbox">
                    <ImageSlider photos={apartment.photos} />
                </div>

                <ApartmentInfoBox apartment={apartment} />

            </div>

            <div className="containerFlex">
                <div className="leftbox">
                    {apartment.unitDescription != null ? (
                        <DescriptionBox header="Unit Description" description={apartment.unitDescription} />
                    ) : null}

                    {apartment.buildingDescription != null ? (
                        <DescriptionBox header="Building Description" description={apartment.buildingDescription} />
                    ) : null}

                    <BuildingInfoBox apartment={apartment} />
                    <ParkingInfoBox apartment={apartment} />
                    <SingleApartmentMap apt={apartment} />
                    <br />
                    <br />

                </div>

                <div className="rightboxempty"> </div>

            </div>

        </div>

    );

}