import React, { useState, useEffect, useRef } from "react";
import "./FiltersPage.css";
import MultiSelectCheckbox from "./MultiSelectCheckbox";
import { neighborhoodOptionsByCity } from "../constants";

export default function FiltersPage({ currentFilters, cancelPressed, allNeighborhoods: selectedNeighborhoods = [], lastCitySelected }) {

    const [filters, setFilters] = useState(currentFilters);
    const [selected, setSelected] = useState(selectedNeighborhoods);

    const [citySelected, setCitySelected] = useState(lastCitySelected);
    const isInitialMount = useRef(true); // Ref to track the initial mount



    //EFFECTS

    useEffect(() => {
        setFilters(currentFilters); // Update filters when currentFilters changes
    }, [currentFilters]);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false; // Set to false after first render
        } else {
            // This code now runs only on updates, not on initial mount
            setSelected([]);
            setFilters(prevFilters => ({
                ...prevFilters,
                "city_neighborhood": selected
            }))
        }
    }, [citySelected])

    //FUNCTIONS

    function getSelectedURLText() {
        const selString = encodeURIComponent(selected.join(","));

        return selString;

    }

    function handleSelectChange(event) {
        const { name, value } = event.target;
        setFilters(prevFilters => ({
            ...prevFilters,
            [name]: value
        }));
    }

    function handleSearchPressed() {
        if (filters === currentFilters) {
            cancelPressed();
        } else {
            window.location.href = "/?max_rent=" + filters.max_rent + "&min_bed=" + filters.min_bed + "&baths=" + String(filters.baths) + "&neighborhoods=" + getSelectedURLText() + "&city=" + citySelected;
        }
    }

    function handleSelectedChange(selectedNeighborhoods) {

        setSelected(selectedNeighborhoods);

        setFilters(prevFilters => ({
            ...prevFilters,
            "city_neighborhood": selected
        }))
    }

    return (
        <div className="filter-container">
            <h2 className="text-center"> Adjust Your Search </h2>
            <h4> Basic Information </h4>
            <div className="text-center">
                <div className="dropdown-container">
                    <label htmlFor="min_bed"><p>Number of Bedrooms</p></label>
                    <select id="min_bed" name="min_bed" value={filters.min_bed} onChange={handleSelectChange}>
                        <option value="0">Studio</option>
                        {[...Array(11).keys()].slice(1).map(bed => (
                            <option key={bed} value={bed}>{bed} {bed === 1 ? "Bedroom" : "Bedrooms"}</option>
                        ))}
                    </select>
                </div>

                <div className="dropdown-container">
                    <label htmlFor="baths"><p>Number of Bathrooms</p></label>
                    <select id="baths" name="baths" value={filters.baths} onChange={handleSelectChange}>
                        {[...Array(11).keys()].slice(1).map(bath => (
                            <option key={bath} value={bath}>{bath} {bath === 1 ? "Bathroom" : "Bathrooms"} </option>
                        ))}
                    </select>
                </div>

                <div className="dropdown-container">
                    <label htmlFor="max_rent"><p>Maximum Budget</p></label>
                    <select id="max_rent" name="max_rent" value={filters.max_rent} onChange={handleSelectChange}>
                        {[...Array(50).keys()].map(budget => (
                            <option key={budget} value={(budget + 1) * 1000}>${(budget + 1) * 1000}</option>
                        ))}
                    </select>
                </div>
                <hr />
                <h4 className="text-left"> Neighborhoods</h4>
                <div className="dropdown-container">
                    <label htmlFor="townCity"><p>Town/City</p></label>
                    <select id="townCity" name="townCity" value={citySelected} onChange={e => setCitySelected(e.target.value)}>
                        <option key={"Boston"} value={"Boston"}> Boston </option>
                        <option key={"Brookline"} value={"Brookline"}> Brookline </option>
                        <option key={"Cambridge"} value={"Cambridge"}> Cambridge </option>
                        <option key={"Somerville"} value={"Somerville"}> Somerville </option>
                        <option key={"Newton"} value={"Newton"}> Newton </option>


                    </select>
                </div>
                <h5> Subdistrict(s) </h5>
                <MultiSelectCheckbox options={neighborhoodOptionsByCity[citySelected]} selected={selected} onCheckboxChange={handleSelectedChange} />

                <br />
                <hr />

                <button onClick={handleSearchPressed} style={{ marginRight: "10px" }}>FIND APARTMENTS</button>
                <button onClick={cancelPressed}>Cancel</button>
            </div>
        </div>
    );
}
