//export const API_BASE_URL = "https://ninth-park-409322.uk.r.appspot.com/api"; //OLD
//export const API_BASE_URL = "http://localhost:3001/api"; //TESTING
export const API_BASE_URL = "https://r20-rent-idx.onrender.com/api"; //process.env.API_BASE_URL;  //PRODUCTION

export const neighborhoodOptionsByCity = {
    "Boston": ["Boston:Allston", "Boston:Back Bay", "Boston:Bay Village", "Boston:Beacon Hill", "Boston:Brighton", "Boston:Charlestown",
        "Boston:Chinatown", "Boston:Dorchester", "Boston:East Boston", "Boston:Fenway", "Boston:Financial District", "Boston:Fort Hill",
        "Boston:Hyde Park", "Boston:Jamaica Plain", "Boston:Kenmore", "Boston:Leather District", "Boston:Mattapan", "Boston:Midtown", "Boston:Mission Hill",
        "Boston:North End", "Boston:Roslindale", "Boston:Roxbury", "Boston:Seaport District", "Boston:South Boston", "Boston:South End", "Boston:Theatre District", "Boston:Waterfront",
        "Boston:West End", "Boston:West Roxbury"],
    "Brookline": ["Brookline:Beaconsfield", "Brookline:Brookline Hills", "Brookline:Brookline Village", "Brookline:Chestnut Hill", "Brookline:Coolidge Corner", "Brookline:Longwood", "Brookline:Reservoir", "Brookline:Washington Square"],
    "Cambridge": ["Cambridge:Agassiz", "Cambridge:Cambridge Highlands", "Cambridge:Cambridgeport", "Cambridge:Central Square", "Cambridge:East Cambridge", "Cambridge:East Cambridge",
        "Cambridge:Harvard Square", "Cambridge:Huron Village", "Cambridge:Inman Square", "Cambridge:Kendall Square", "Cambridge:Mid Cambridge", "Cambridge:Neighborhood Nine", "Cambridge:North Cambridge",
        "Cambridge:Porter Square", "Cambridge:Riverside", "Cambridge:Wellington-Harrington", "Cambridge:West Cambridge"],
    "Somerville": ["Somerville:Assembly Square", "Somerville:Ball Square", "Somerville:Davis Square", "Somerville:East Somerville", "Somerville:Inman Square", "Somerville:Magoun Square", "Somerville:Powderhouse Square",
        "Somerville:Prospect Hill", "Somerville:Spring Hill", "Somerville:Teele Square", "Somerville:Ten Hills", "Somerville:Union Square", "Somerville:West Somerville", "Somerville:Winter Hill"],
    "Newton": ["Newton:Auburndale", "Newton:Chestnut Hill", "Newton:Eliot", "Newton:Newton Center", "Newton:Newton Corner", "Newton:Newton Highlands", "Newton:Newton Lower Falls", "Newton:Newton Upper Falls", "Newton:Newtonville",
        "Newton:Nonantum", "Newton:Thompsonville", "Newton:Waban", "Newton:West Newton", "Newton:Woodland Station"]
}

export const MAP_TILE_URL = "http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png";

export const FRONT_PAGE_FILTERS = {
    min_bed: 0,
    min_rent: 1000
}

export const ERROR_MESSAGE_LISTING = "An error occurred :( Apartment data is not available. Please try again later.";
export const ERROR_MESSAGE_FRONTPAGE = "No Apartments Found. Try adjusting your Search.";

//IMAGES
export const IMGLINK_PHOTOS_COMING_SOON = "https://realty20.com/wp-content/uploads/2023/12/Photos-Coming-Soon.png";
export const IMGLINK_MAP_ICON = "https://realty20.com/wp-content/uploads/2023/12/Icon-1.png";

export const FRONTPAGE_SCROLL = false; //process.env.FRONTPAGE_SCROLL;
export const SHOW_HEADER = true;