import React from 'react';
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

const SingleApartmentMap = ({ apt }) => {

    const centerPos = [apt.latitude, apt.longitude]; // Center position for the map

    // Create a custom icon
    const customIcon = L.icon({
        iconUrl: "https://realty20.com/wp-content/uploads/2023/12/Icon-1.png",
        iconSize: [35, 35],
        iconAnchor: [12, 41],
        popupAnchor: [0, -41]
    });



    return (
        <div className="infobox">
            <h3> Location </h3>
            <MapContainer center={centerPos} zoom={16} style={{ height: '300px', width: '100%' }}>
                <TileLayer
                    url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
                />

                <Marker position={[apt.latitude, apt.longitude]} icon={customIcon} key={apt.id}> </Marker>

            </MapContainer>
        </div>
    );
};

export default SingleApartmentMap;
