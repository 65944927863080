import react from 'react';

export default function DescriptionBox({ header, description }) {

    return (

        <>
            <div className="infobox">
                <h3> {header} </h3>
                <p> {description} </p>
            </div>
        </>

    );
}