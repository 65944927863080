import React from 'react';
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { IMGLINK_MAP_ICON, IMGLINK_PHOTOS_COMING_SOON, MAP_TILE_URL } from '../constants';

const MapComponent = ({ apts, height = "95%" }) => {

    const centerPos = [42.34, -71.09]; // Center position for the map

    // Create a custom icon
    const customIcon = L.icon({
        iconUrl: IMGLINK_MAP_ICON,
        iconSize: [35, 35],
        iconAnchor: [12, 41],
        popupAnchor: [0, -41]
    });

    // Group apartments by address
    const groupedApartments = apts.reduce((acc, apt) => {
        const address = apt.streetNumber + " " + apt.streetName;
        if (!acc[address]) {
            acc[address] = {
                position: [apt.latitude, apt.longitude],
                units: []
            };
        }
        acc[address].units.push(apt);
        return acc;
    }, {});

    return (
        <MapContainer center={centerPos} zoom={12} scrollWheelZoom={false} style={{ height: height, width: '100%' }}>
            <TileLayer
                //url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
                url={MAP_TILE_URL}
            />
            {Object.keys(groupedApartments).map(address => {
                const { position, units } = groupedApartments[address];
                return (
                    <Marker position={position} icon={customIcon} key={address}>
                        <Popup>
                            <img src={units[0].photos.length > 0 ? units[0].photos[0] : IMGLINK_PHOTOS_COMING_SOON} style={{ width: "100%", height: "auto" }} />
                            <h4 className="text-center">{address}</h4>
                            {units.slice(0, 4).map(unit =>
                                <>
                                    <a className="text-center" href={"/apartment/" + unit.id} key={unit.id}>
                                        {unit.bedInfo.length > 3 ? unit.bedInfo : unit.bedInfo + " Beds"} | {unit.baths} Baths | ${unit.price}</a> <br />
                                </>)}
                        </Popup>
                    </Marker>
                );
            })}
        </MapContainer>
    );
};

export default MapComponent;
