import React, { useState, useEffect, useRef } from 'react';
import '../main-styles.css';
import ApartmentThumbnail from '../components/ApartmentThumbnail';
import TopBar from '../components/TopBar';
import MapComponent from '../components/MapComponent';
import FiltersPage from '../filterspage/FiltersPage';
import { Link, useLocation } from 'react-router-dom';
import { API_BASE_URL, ERROR_MESSAGE_FRONTPAGE, FRONT_PAGE_FILTERS } from "../constants.js";
import Pagination from '../components/Pagination';
import ClipLoader from "react-spinners/ClipLoader";

function FrontPageVertical() {
    const [allFetchedApartments, setAllFetchedApartments] = useState([]);
    const [apartmentsOnPage, setApartmentsOnPage] = useState([]);
    const [pageIndex, setPageIndex] = useState(1);
    const [totalListingsInQuery, setTotalListingsInQuery] = useState(0);

    const [isLoading, setIsLoading] = useState(false);
    const [numOfFetches, setNumOfFetches] = useState(0);
    const [isEndOfPages, setIsEndOfPages] = useState(false);
    const [isFiltersOpen, setIsFiltersOpen] = useState(false);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const boxFlexLeftRef = useRef(null);

    const apartmentsPerPage = 12;
    const listingsPerCall = 100;

    const [filters, setFilters] = useState({
        min_bed: searchParams.get('min_bed') || FRONT_PAGE_FILTERS.min_bed,
        baths: searchParams.get('baths') || FRONT_PAGE_FILTERS.baths,
        max_rent: searchParams.get('max_rent') || FRONT_PAGE_FILTERS.max_rent,
        city_neighborhood: searchParams.get('neighborhoods') || searchParams.get('city') || FRONT_PAGE_FILTERS.city_neighborhood
    });

    useEffect(() => {
        const startIndex = (pageIndex - 1) * apartmentsPerPage;
        const endIndex = Math.min(startIndex + apartmentsPerPage, allFetchedApartments.length);
        if (startIndex + apartmentsPerPage >= allFetchedApartments.length && (endIndex < totalListingsInQuery || (totalListingsInQuery == 0 && numOfFetches < 1))) {
            fetchApartments();
        } else {
            const newApartments = allFetchedApartments.slice(startIndex, endIndex);
            setApartmentsOnPage(newApartments);
            setIsEndOfPages(newApartments.length < apartmentsPerPage || startIndex + apartmentsPerPage >= totalListingsInQuery);
        }
    }, [pageIndex, allFetchedApartments, isLoading, totalListingsInQuery, numOfFetches]);

    useEffect(() => {
        if (boxFlexLeftRef.current) {
            boxFlexLeftRef.current.scrollTo(0, 0);
        }
    }, [pageIndex]);

    function fetchApartments() {
        if (!isLoading) {
            setIsLoading(true);
            setNumOfFetches(numOfFetches + 1);
            fetch(`${API_BASE_URL}/rentals`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    include_mls: '0',
                    request_type: 'JSON',
                    page_count: listingsPerCall,
                    page_index: numOfFetches,
                    min_rent: 1500,
                    ...filters
                })
            })
                .then(response => response.json())
                .then(data => {
                    const newApartments = data["listings"] || [];
                    setTotalListingsInQuery(data["total"]);
                    setAllFetchedApartments(prevApartments => [...prevApartments, ...newApartments]);
                    setApartmentsOnPage(newApartments.slice(0, apartmentsPerPage));
                    setIsLoading(false);
                })
                .catch(error => console.error('Error:', error));
        }
    };

    return (
        isFiltersOpen ? <FiltersPage currentFilters={filters} cancelPressed={() => setIsFiltersOpen(false)} lastCitySelected={searchParams.get('city') === null ? "Boston" : searchParams.get('city')} selectedNeighborhoods={searchParams.get('neighborhoods') === null ? [] : decodeURIComponent(searchParams.get('neighborhoods')).split(",")} /> :
            <div className="main-body-container">
                <TopBar filterPressed={() => setIsFiltersOpen(true)} totalFound={totalListingsInQuery} />

                <div className="map-vertical-box">
                    <MapComponent apts={allFetchedApartments} height="500px" />
                </div>

                <hr className="map-vertical-box" />

                <div className="containerFlex">
                    {isLoading ? <div className="loading-indicator">

                        <ClipLoader size={45} color={"#202a54"} loading={isLoading} />
                    </div> :
                        <>
                            <div className="containerFlex-ApartmentThumbnails-Vertical">
                                {(totalListingsInQuery === 0 && numOfFetches > 0) ? <p>{ERROR_MESSAGE_FRONTPAGE}</p> :
                                    apartmentsOnPage.map(apartment => (
                                        <Link to={{
                                            pathname: `/apartment/${apartment.id}`,
                                            state: { apartment }
                                        }}>
                                            <ApartmentThumbnail apt={apartment} />
                                        </Link>
                                    ))}
                            </div>
                        </>

                    }
                </div>
                <div className="text-center" style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                    {isLoading ? null : <Pagination pageIndex={pageIndex} onPageChange={setPageIndex} endOfPages={isEndOfPages} />}
                </div>

            </div>
    );
}

export default FrontPageVertical;
