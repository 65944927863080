import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/header";
import FiltersPage from "./filterspage/FiltersPage";
import FrontPage from "./frontpage/FrontPage";
import FrontPageVertical from "./frontpage/FrontPage-Vertical";
import ListingPage from "./listingpage/ListingPage"
import { SHOW_HEADER, FRONTPAGE_SCROLL } from "./constants";

export const AllRoutes = () => {
    return (
        <Router>
            {SHOW_HEADER ? <Header /> : <></>}
            <Routes>
                <Route path="/" exact element={FRONTPAGE_SCROLL ? <FrontPage /> : <FrontPageVertical />} />
                <Route path="/apartment/:id" element={<ListingPage />} />
            </Routes>
        </Router>
    );
}