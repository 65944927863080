import React from 'react';
import './ApartmentThumbnail.css'; // make sure to create this CSS file
import "../main-styles.css";


export default function ApartmentThumbnail({ apt }) {

  const photo = apt.photos.length > 0 ? apt.photos[0] : "https://realty20.com/wp-content/uploads/2023/12/Photos-Coming-Soon.png"
  console.log(apt.availableDate === null);
  return (
    <div className="thumbnail">

      <div className="image-container">
        <img src={photo} alt="Apartment" className="apartment-image" />
        <div className="availability-bar" style={{ backgroundColor: (apt.availableDate != null ? "rgba(48, 62, 122, .6)" : "rgba(255,17,0, .6)") }}>
          <h5 style={{ color: "white", margin: "2px" }}>Available {apt.availableDate != null ? apt.availableDate : "NOW"}!</h5>
        </div>
      </div>

      <div className="details">
        <h5 className="address" style={{ margin: "1px", fontSize: "10px" }}> Apartment for Rent </h5>
        <p className="address">{apt.streetNumber} {apt.streetName}</p>
        <p className="city"> {apt.city}, {apt.state} {apt.zip} </p>
        <div className="containerFlex" style={{ alignItems: "center" }}>
          <p className="info"> 🛏️ {apt.bedInfo}</p>
          <p className="info">🚽 {apt.baths}</p>
          <p className="info">${apt.price} / Mo</p>
        </div>

        <button className="button" style={{ width: "100%" }}> Connect with an Agent </button>
      </div>
      <div className="containerFlex">
      </div>
    </div >
  );
}

/*

      <button className="view-listing-btn"><b>View Listing</b></button>
        <button style={{ width: "22vh", marginBottom: "10px" }}> Favorite </button>

      */