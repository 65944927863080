import './main-styles.css';
import { AllRoutes } from './Routes';

function App() {

  return (
    <AllRoutes />
  )
}

export default App;

/*

*/
