import react from 'react';
import { useState } from 'react';
import "./ApartmentInfoBox.css";

export default function ApartmentInfoBox({ apartment }) {

    const [isCopied, setIsCopied] = useState(false); // State to control the visibility of the copied message

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText("www.realty20.com/rentals/?listing_id=" + apartment.id);

            setIsCopied(true); // Show the copied message
            setTimeout(() => {
                setIsCopied(false); // Hide the message after 3 seconds
            }, 3000); // Adjust time as needed


        } catch (err) {
            alert("failed to copy: ", err);
        }
    }
    return (
        <div className="rightbox">
            <h2 style={{ fontWeight: "700" }}> {apartment.streetNumber} {apartment.streetName} </h2>
            <h5 className="main-city"> {apartment.city}, {apartment.state} {apartment.zip} </h5>
            <hr />

            <div className="containerLeftToRight">
                <p> 📅 Available </p>
                <p style={{ fontWeight: "300", color: "var(--r20-darktext)" }}> {apartment.availableDate} </p>
            </div>

            <div className="containerLeftToRight">
                <p>🛏️ Beds</p>
                <p style={{ fontWeight: "300", color: "var(--r20-darktext)" }}> {apartment.bedInfo} </p>
            </div>

            <div className="containerLeftToRight">
                <p>🚽 Baths</p>
                <p style={{ fontWeight: "300", color: "var(--r20-darktext)" }}> {apartment.baths} </p>
            </div>

            <hr />

            <h3> <span style={{ color: "var(--r20-purple)" }}> ${apartment.price} </span> <span style={{ color: "gray", fontSize: "small", fontWeight: "normal" }}> / Month </span> </h3>
            <div className="text-center">
            </div>
        </div>

    )

}

//                {isCopied && <p> The link was copied to your clipboard! </p>}
