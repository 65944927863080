import { React, useState } from "react";
import Pagination from "./Pagination";

export default function TopBar({ filterPressed, totalFound }) {


    return (
        <>
            <div style={{ paddingLeft: "30px", paddingBottom: "0px", paddingTop: "20px" }}>
                <div className="containerFlex-StartFromLeft">
                    <span style={{ color: "#303e7a", fontSize: "20px", fontWeight: "600" }}>{totalFound}</span><span style={{ color: "grey", fontSize: "17px", fontWeight: "300" }}>Apartments Found </span>
                    <button onClick={filterPressed}> Adjust Search </button>
                </div>
            </div>
            <hr />
        </>
    )

}