import react from 'react';
import HeadingInfoDetail from './HeadingInfoDetail';

export default function BuildingInfoBox({ apartment }) {

    return (
        <div className="infobox">
            <h3> Building Info </h3>
            <div className="containerGridLeft">
                <HeadingInfoDetail heading="Building Type" detail={apartment.buildingType === null ? "N/A" : apartment.buildingType} />
                <HeadingInfoDetail heading="Unit Level" detail={apartment.unitLevel === null ? "N/A" : apartment.unitLevel} />
                <HeadingInfoDetail heading="Utilities Included" detail=
                    {
                        apartment.rentIncludes && apartment.rentIncludes.length > 0
                            ? apartment.rentIncludes.join(', ')
                            : "N/A"
                    } />

                <HeadingInfoDetail heading="Heat Source" detail={apartment.heatSource === null ? "N/A" : apartment.heatSource} />

            </div>
        </div>
    )
}