import React, { useState } from 'react';
import "./MultiSelectCheckbox.css"

const MultiSelectCheckbox = ({ options = [], selected, onCheckboxChange }) => {

    const handleOnChange = (event) => {
        const selectedOption = event.target.value;
        if (selected.includes(selectedOption)) {
            onCheckboxChange(selected.filter(option => option !== selectedOption));
        } else {
            onCheckboxChange([...selected, selectedOption]);
        }

    };

    return (
        <div className="containerGridLeft">
            {options.map(option => (
                <label key={option}>
                    <input
                        type="checkbox"
                        value={option}
                        checked={selected.includes(option)}
                        onChange={handleOnChange}
                    />
                    {option}
                </label>
            ))}
        </div>
    );
};

export default MultiSelectCheckbox;