import React from 'react';
import "./header.css";

function Header() {
  return (
    <>
      <header>
        <div>
          <div className="brxe-container" id="brxe-99c803">
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", maxWidth: "1100px", width: "100%", marginLeft: "auto", marginRight: "auto" }}>
              <a id="brxe-8cc959" class="brxe-logo" href="https://realty20.com" element-id="213"><img width="320" height="132" src="https://mlv4zd18oeej.i.optimole.com/w:320/h:132/q:mauto/f:best/https://realty20.com/wp-content/uploads/2023/12/Google-Workspace-Transparent-1.png" class="bricks-site-logo css-filter has-transparency" alt="REALTY20" loading="eager" decoding="async" data-has-transparency="true" data-dominant-color="000000" element-id="212" data-opt-lazy-loaded="false" /></a>
            </div>

          </div>
        </div>
      </header>
    </>
  );
}

export default Header;

/*
        <a href="https://www.realty20.com" style={{marginRight: "25px"}}> Home</a>

*/